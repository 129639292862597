<template>
    <div>
        <div class="view pa24 articleTop">
            <div class="d-flex backColorFFF">
                <div style="margin-left:auto">
                    <el-button type="primary" :disabled="!checkedAppid" @click="getList()"
                        icon="el-icon-refresh"></el-button>
                    <el-button type="primary" @click="showModel = true">{{ appName || '选择公众号' }}</el-button>
                </div>
            </div>
            <div class="backColorFFF">
                <el-tabs v-model="status" @tab-click="tabClick">
                    <el-tab-pane :label="name" :name="id" :key="id" v-for="(name, id) of typeData"
                        :disabled="loading"></el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <h4 v-if="checkedAppid === 0" class="flex-c-c view pa24">请选择公众号</h4>
        <h4 v-else-if="tableData.length == 0" v-loading="loading" class="flex-c-c view pa24">暂无文章数据</h4>
        <div v-else v-loading="loading">
            <div class="d-flex flex-wrap-y mt10">
                <div class="article mr10 mb10" v-for="(row, index) in tableData" :key="index">
                    <div class="articleList" v-for="(child, key) in row.content.news_item" :key="key">
                        <div class="articleMaxPic" @click="hrefFun(child.url)" v-if="key == 0"
                            :style="{ 'background-image': 'url(https://api.zhengce365.com/wxapi.php?url=' + child.thumb_url + ')' }">
                            <div class="articleMaxTitle">{{ child.title }}</div>
                        </div>
                        <div class="articleItem flex-a-b-c" @click="hrefFun(child.url)" v-else>
                            <div class="articleTitle text-overflow2">{{ child.title }}</div>
                            <el-image class="articlePic"
                                :src="'https://api.zhengce365.com/wxapi.php?url=' + child.thumb_url"></el-image>
                        </div>
                    </div>
                    <div class="flex-a-b-c operate">
                        <div>{{ getDataTimeSec(row.update_time * 1000) }}</div>
                        <div class="operateFun">
                            <el-dropdown @command="operateFun($event, row.media_id)" v-if="status == 1">
                                <i class="el-icon-more el-dropdown-link"></i>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="delArticleList" style="color:#F56C6C"
                                        icon="el-icon-delete-solid">删 除</el-dropdown-item>
                                    <el-dropdown-item command="sendPublish" icon="el-icon-s-promotion">发
                                        布</el-dropdown-item>
                                    <el-dropdown-item command="sendPreview"
                                        icon="el-icon-s-promotion">推送预览</el-dropdown-item>
                                    <el-dropdown-item command="sendAll" icon="el-icon-s-promotion">群 发</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-dropdown @command="operateFun($event, row.article_id)" v-if="status == 2">
                                <i class="el-icon-more el-dropdown-link"></i>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="delArticleList" style="color:#F56C6C"
                                        icon="el-icon-delete-solid">删 除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="view pa5 mb10" v-if="tableData.length > 0">
            <el-pagination background layout="prev, pager, next, total" :current-page="currentPage"
                @current-change="handleCurrentChange" :total="total" :page-size="pageSize">
            </el-pagination>
        </div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :close-on-press-escape="false" title="选择公众号"
            :visible.sync="showModel" :destroy-on-close="true" width="50%" center>
            <div class="flex-a-c flex-wrap-y">
                <div v-for="(row, index) in accountList" :key="index" class="flex-a-a-c accountList"
                    :class="{ 'on': checkedAppid == row.appId }" @click="checkedAccount(row)">
                    <el-image class="companyLogo" :src="userInfo.companyLogo"></el-image>
                    <div class="appName text-overflow">
                        {{ row.appName }}
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getOfficialAccountList, getBatchList } from "@/api/wechat";
import { deleteArticle, deleteDraft, publishNew,sendAll } from "@/api/authorization";
import { getDataTimeSec } from "@/utils";
export default {
    name: "parkingLotManagement",
    components: {
    },
    data() {
        return {
            //列表配置
            typeData: {
                1: "草稿箱",
                2: "已发布"
            },
            status: "1",
            checkedAppid: 0,
            appName: "",
            currentPage: 1, //当前页
            pageSize: 8, //显示条数
            loading: true, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            userInfo: this.$store.state.loginRoot.userInfo,
            accountList: [],
            gzhErrcode: this.$store.state.gzhErrcode
        };
    },
    computed: {
        getDataTimeSec() {
            return (time) => {
                return getDataTimeSec(time)
            }
        }
    },
    created() {
        this.getOfficialAccountList();
    },
    methods: {
        tabClick(){
            this.currentPage=1;
            this.getList();
        },
        /**@method 跳转 */
        hrefFun(url) {
            window.open(url)
        },
        /**@method 文章操作 */
        operateFun(type, media_id) {
            if (type === 'delArticleList') {//删除文章
                this.$confirm("该操作会同步到公众号，是否继续删除?", "提示", {
                    onConfirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info ",
                }).then(() => {
                    this.delArticleList(media_id);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else if (type === "sendPreview") {//发送预览
                this.$prompt('请输入微信号', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPlaceholder: "可使用 “,” 分隔，推送多个预览"
                }).then(({ value }) => {
                    this.sendPreview(media_id, value);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });
                });
            } else if (type === "sendPublish") {//发布
                this.sendPublish(media_id);
            } else if (type === "sendAll") {
                this.sendAll(media_id);
            }
        },
        /**@method 群发 */
        sendAll(media_id) {
            this.$confirm('每天只能群发一次, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: "info",
                    message: "群发中..."
                })
                let params = {
                    mediaId: media_id,
                    authorizerAppid: this.checkedAppid
                }
                sendAll(params).then(res => {//删除草稿箱文章
                    if (res.code === 0) {
                        this.$message({
                            type: 'success',
                            message: "群发成功,等公众号管理员通过"
                        })
                    } else {
                        this.$message.error(this.gzhErrcode[res.code]);
                    }
                }).catch(err => {
                    this.$message.error(this.gzhErrcode[err.code]);
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        },
        /**@method 删除文章 */
        delArticleList(media_id) {
            this.$message({
                type: "info",
                message: "删除中..."
            })
            //删除文章
            let params = {
                authorizerAppid: this.checkedAppid
            };
            if (this.status == 1) {
                params.mediaId = media_id
                deleteDraft(params).then(res => {//删除草稿箱文章
                    if (res.code === 0) {
                        this.currentPage = 1;
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: "删除成功！"
                        })
                    } else {
                        this.$message.error(this.gzhErrcode[res.code]);
                    }
                }).catch(err => {
                    this.$message.error(this.gzhErrcode[err.code]);
                })
            } else {
                params.articleId = media_id
                deleteArticle(params).then(res => {//删除已发布文章
                    if (res.code === 0) {
                        this.currentPage = 1;
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: "删除成功！"
                        })
                    } else {
                        this.$message.error(this.gzhErrcode[res.code]);
                    }
                }).catch(err => {
                    this.$message.error(this.gzhErrcode[err.code]);
                })
            }
        },
        /**@method 发送预览 */
        sendPreview(media_id, towxname) {
            this.$message({
                type: "info",
                message: "推送中..."
            })
            let params = {
                publish: false,
                mediaId: media_id,
                towxname: towxname,
                authorizerAppid: this.checkedAppid
            }
            publishNew(params).then(res => {//删除草稿箱文章
                if (res.code === 200) {
                    this.getList();
                    this.$message({
                        type: 'success',
                        message: res.data
                    })
                } else {
                    this.$message.error(this.gzhErrcode[res.code]);
                }
            }).catch(err => {
                this.$message.error(this.gzhErrcode[err.code]);
            })
        },
        /**@method 发布 */
        sendPublish(media_id) {
            this.$message({
                type: "info",
                message: "发布中..."
            })
            let params = {
                mediaId: media_id,
                authorizerAppid: this.checkedAppid
            }
            publishNew(params).then(res => {//删除草稿箱文章
                if (res.code === 0) {
                    this.$message({
                        type: 'success',
                        message: "发布成功"
                    })
                } else {
                    this.$message.error(this.gzhErrcode[res.code]);
                }
            }).catch(err => {
                this.$message.error(this.gzhErrcode[err.code]);
            })
        },
        /**@method 选中公众号 */
        checkedAccount(row) {
            this.checkedAppid = row.appId;
            this.appName = row.appName;
            this.showModel = false;
            this.currentPage = 1;
            this.getList();
        },
        /**@method 获取公众号 */
        getOfficialAccountList() {
            getOfficialAccountList({ isPage: false }).then(res => {
                if (res.code === 200) {
                    if (res.data) {
                        this.accountList = res.data;
                        this.checkedAccount(res.data[0]);
                    }
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 获取列表 */
        getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                authorizerAppid: this.checkedAppid,
                noContent: 1
            };
            this.loading = true;
            if (this.status == 1) {
                params.publish = false;
            } else {
                params.publish = true;
            }
            getBatchList(params).then(res => {
                if (res.data) {
                    this.loading = false;
                    const { data } = res;
                    this.tableData = data.item;
                    this.total = data.total_count;
                } else {
                    this.loading = false;
                    this.tableData = [];
                    this.total = 0;
                }
            }).catch(err => {
                this.loading = false;
                this.tableData = [];
                this.total = 0;
            })
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.articleTop {
    padding-bottom: 0;
}

.backColorFFF {
    background: #fff;
}

.accountList {
    width: 150px;
    height: 70px;
    padding: 10px;
    border: 2px solid #bebebe;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    .companyLogo {
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }

    .appName {
        width: calc(100% - 70px);
        text-align: center;
    }
}

.accountList.on {
    border: 2px solid #51CBCD;
}


.article {
    width: calc(25% - 7.5px);
    background: #fff;
    position: relative;
    padding-bottom: 30px;

    .articleList {
        cursor: pointer;

        .articleMaxPic {
            position: relative;
            width: 100%;
            height: 180px;
            background-size: 100% 100%;

            .articleMaxTitle {
                position: absolute;
                width: 100%;
                bottom: 0;
                color: #fff;
                padding: 10px;
                background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
            }
        }

        .articleItem {
            padding: 10px;
            color: #000;
            border-bottom: 1px solid #eee;

            .articlePic {
                height: 60px;
                width: 60px;
            }

            .articleTitle {
                width: calc(100% - 80px);
            }
        }
    }

    .operate {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 30px;
        line-height: 30px;
        background: #fff;
        padding: 10px;

        .operateFun {
            cursor: pointer;

            .el-icon-more {
                color: #000;
            }
        }
    }
}

.article:nth-child(4n) {
    margin-right: 0 !important;
}
</style>